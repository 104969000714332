<template>
  <div class="wrapper wrapper--default p-2 m-1">
    <Loading v-if="loading" />
    <h2 class="text-center" v-if="!loading">{{ this.customer_info.nummer }}</h2>
    <Tabs v-if="!loading" :key="id">
      <Tab title="Klant" :selected="true">
        <h3 class="text-center">Klant informatie</h3>
        <div class="d-flex flex-row">
          <div class="flex-grow-1 m-1">
            <ClickableTextBox
              v-if="customer_info.accountid"
              :label="`Nummer`"
              :value="`${customer_info.nummer}`"
              :link="
                `https://kleyn.crm4.dynamics.com/main.aspx?appid=a1971671-a4b2-e911-a849-000d3a2bd64e&pagetype=entityrecord&etn=account&id=${customer_info.accountid}`
              "
            />
            <TextBox
              v-else
              :label="`Nummer`"
              :value="`${customer_info.nummer}`"
            />
            <TextBox :label="`Naam`" :value="`${customer_info.naam}`" />
            <TextBox :label="`Adres`" :value="`${customer_info.adres}`" />
            <TextBox v-if="customer_info.adres2" :label="`Adres 2`" :value="`${customer_info.adres2}`" />
            <TextBox :label="`Plaats`" :value="`${customer_info.stad}`" />
            <TextBox :label="`Postcode`" :value="`${customer_info.postcode}`" />
            <br>

          </div>
          <div class="flex-grow-1 m-1">
            <TextBox :label="`Land`" :value="`${customer_info.land}`+' - '+`${customer_info.landnaam}`" />
            <TextBox
              :label="`Categorie`"
              :value="`${customer_info.catagorie}`"
            />
            <TextBox
              :label="`Contactinfo`"
              :value="`${customer_info.contactinfo}`"
            />
            <TextBox
              :label="`Contact`"
              :value="`${customer_info.contactpersoon}`"
            />
          </div>
          <div class="flex-grow-2 m-3">
            <table class="table__border--default">
              <thead class="table__head--default">
                <th class="table__head--text">Jaar</th>
                <th class="table__head--text">Aantal</th>
                <th
                  class="table__head--text"
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                >
                  Omzet
                </th>
                <th
                  class="table__head--text"
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                >
                  Winst
                </th>
                <th
                  class="table__head--text"
                  v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                >
                  Commissie
                </th>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in customer_sales_history"
                  :key="key"
                  class="table__row--color"
                >
                  <td class="table__cell--default">{{ item.jaar }}</td>
                  <td class="table__cell--default">{{ item.aantal }}</td>
                  <td
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__cell--default"
                  >
                    € {{ item.omzet }}
                  </td>
                  <td
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__cell--default"
                  >
                    € {{ item.winst }}
                  </td>
                  <td
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__cell--default"
                  >
                    € {{ item.commisie }}
                  </td>
                </tr>
                <tr class="table__foot">
                  <th class="table__foot--padding">Totaal:</th>
                  <th class="table__foot--padding">
                    {{ sales_history_total.aantal }}
                  </th>
                  <th
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__foot--padding"
                  >
                    € {{ sales_history_total.omzet }}
                  </th>
                  <th
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__foot--padding"
                  >
                    € {{ sales_history_total.winst }}
                  </th>
                  <th
                    v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                    class="table__data--right table__foot--padding"
                  >
                    € {{ sales_history_total.commissie }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="mt-3 flex-grow-1">
            <table class="table__border--default">
              <thead class="table__head--default">
                <th class="table__head--text">Naam</th>
                <th class="table__head--text">Email</th>
                <th class="table__head--text">Soort</th>
                <th class="table__head--text">Telefoon</th>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in customer_contact_info"
                  :key="key"
                  class="table__row--color"
                >
                  <td class="table__cell--default">{{ item.naam }}</td>
                  <td class="table__cell--default">{{ item.email }}</td>
                  <td class="table__cell--default">{{ item.soort }}</td>
                  <td class="table__cell--default">{{ item.telefoon }}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
      </Tab>
      <Tab title="Openstaande posten">
        <button class="m2 employee_download" @click="createExcel">Uitdraai maken</button>
        <table :class="`w-100 table--default table__border--default`">
          <thead class="table__head--default">
            <tr>
              <th class="table__head--text">Doc Type</th>
              <th class="table__head--text">Doc nummer</th>
              <th class="table__head--text">Factuur datum</th>
              <th class="table__head--text">Afleverdatum</th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__head--text"
              >
                Totaal bedrag
              </th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__head--text"
              >
                Openstaand
              </th>
              <th class="table__data--right table__head--text">Omschrijving</th>
              <th class="table__data--right table__head--text" style="width:100px">Bank omschrijving A</th>
              <th class="table__data--right table__head--text">Bank omschrijving B</th>
              <th class="table__data--right table__head--text">Valuta</th>
              <th class="table__data--right table__head--text">
                Valuta bedrag
              </th>
              <th class="table__data--right table__head--text">Valuta open</th>
            </tr>
          </thead>
          <thead>
            <tr class="table__foot line-bottom">
              <td class="table__foot--padding">Totaal:</td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding"></td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__foot--padding"
              >
                € {{ open_post_totals.total_price }}
              </td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__foot--padding"
              >
                € {{ open_post_totals.openstaand }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in open_posts"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">{{ item.type }}</td>
              <td class="table__cell--default">{{ item.factuur }}</td>
              <td class="table__cell--default">{{ item.datum }}</td>
              <td class="table__cell--default">{{ item.afleverdatum }}</td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__cell--default"
              >
                € {{ item.bedrag }}
              </td>
              <td
                v-if="
                  item.openstaand != 0.0 && checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)
                "
                class="table__data--right table__cell--default"
              >
                {{ item.openstaand }}
              </td>
              <td v-else class="table__data--right table__cell--default"></td>
              <td class="table__cell--default">
                {{ item.omschrijving }}
              </td>
              <td  class="table__cell--default">
               {{ item.bankomschrijvingA }}
              </td>
              <td  class="table__cell--default">
               {{ item.bankomschrijvingB }}
              </td>
              <td class="table__data--right table__cell--default">
                {{ item.valuta }}
              </td>
              <td class="table__data--right table__cell--default">
                {{ item.valutabedrag }}
              </td>
              <td class="table__data--right table__cell--default">
                {{ item.openvaluta }}
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Openstaande orders"
        ><table :class="`w-100 table--default table__border--default`">
          <thead :class="`table__head--default`">
            <tr>
              <th class="table__head--text">Order</th>
              <th class="table__head--text">Type</th>
              <th class="table__head--text">Datum</th>
              <th class="table__head--text">Item</th>
              <th class="table__head--text">Omschrijving</th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__head--text"
              >
                Verkoopprijs
              </th>
              <th class="table__data--right table__head--text">Verkoper</th>
            </tr>
          </thead>
          <thead>
            <tr class="table__foot line-bottom">
              <td class="table__foot--padding">{{ open_orders_totals.amount }}</td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding"></td>
              <td class="table__foot--padding table__data--right">€ {{ open_orders_totals.price }}</td>
              <td class="table__foot--padding"></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in open_orders"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default"><router-link
                  :to="{
                    name: 'verkooporderdetails',
                    params: { id: item.orderno },
                  }"
                  >{{ item.orderno }}</router-link></td>
              <td class="table__cell--default">{{ item.type }}</td>
              <td class="table__cell--default">{{ item.datum }}</td>
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.itemno" />
              </td>
              <td>{{ item.omschrijving }}</td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__cell--default"
              >
                € {{ item.prijs }}
              </td>
              <td class="table__data--right table__cell--default">
                {{ item.verkoper }}
              </td>
            </tr>
          </tbody>
        </table></Tab
      >
      <Tab title="Item Historie"
        ><table :class="`w-100 table--default table__border--default`">
          <thead class="table__head--default">
            <tr>
              <th class="table__head--text">Nummer</th>
              <th class="table__head--text">Bu</th>
              <th class="table__head--text">Soort</th>
              <th class="table__head--text">Merk & Type</th>
              <th class="table__head--text">Jaar</th>
              <th class="table__head--text">Verkoper</th>
              <th class="table__head--text">Status</th>
              <th class="table__head--text">Factuur datum</th>
              <th class="table__head--text">Afleverdatum</th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__head--text"
              >
                Verkopen
              </th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__head--text"
              >
                Winst
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in item_history"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.nummer" />
              </td>
              <td class="table__cell--default">{{ item.bu }}</td>
              <td class="table__cell--default">{{ item.soort }}</td>
              <td class="table__cell--default">{{ item.merk }}</td>
              <td class="table__cell--default">{{ item.regjaar }}</td>
              <td class="table__cell--default">{{ item.verkoper }}</td>
              <td class="table__cell--default">{{ item.status }}</td>
              <td class="table__cell--default">{{ item.factuurdatum }}</td>
              <td class="table__cell--default">{{ item.afleverdatum }}</td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__cell--default"
              >
                € {{ item.verkopen }}
              </td>
              <td
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__cell--default"
              >
                € {{ item.winst }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="table__foot">
              <th class="table__foot--padding">Totaal:</th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding"></th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__foot--padding"
              >
                € {{ item_history_total.verkopen }}
              </th>
              <th
                v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
                class="table__data--right table__foot--padding"
              >
                € {{ item_history_total.winst }}
              </th>
            </tr>
          </tfoot>
        </table>
      </Tab>
      <Tab title="Notities">
        <div>
          <b-button v-b-modal.create_note class="m-2"
            ><i class="fas fa-sticky-note" /> + Nieuwe notitie
            toevoegen</b-button
          >
          <b-modal id="create_note" ref="note_modal">
            <template #modal-header>
              <h2>Voeg notitie toe</h2>
            </template>
            <textarea
              class="form-control"
              id="note_field"
              rows="3"
              v-model.lazy="note_context"
            ></textarea>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="postNote"
                >
                  Toevoegen
                </b-button>
              </div>
            </template>
          </b-modal>
          <div>
            <table :class="`w-100 table--default table__border--default`">
              <thead class="table__head--default">
                <tr>
                  <th class="table__head--text">Klant</th>
                  <th class="table__head--text">Geplaatst door</th>
                  <th class="table__head--text">Datum</th>
                  <th class="table__head--text">Aantekening</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in customer_notes"
                  :key="key"
                  class="table__row--color"
                >
                  <td class="table__cell--default">{{ item.Debiteur }}</td>
                  <td class="table__cell--default">{{ item.Gebruiker }}</td>
                  <td class="table__cell--default">{{ item.Datum }}</td>
                  <td class="table__cell--default">
                    <div class="notes__textbox">
                      {{ item.Aantekeningen }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import TextBox from "@/components/TextBox.vue";
import ClickableTextBox from "@/components/ClickableTextBox.vue";
import moment from "moment";
import hasIndex from "@/functions/hasIndex";
import Loading from "@/components/Loading";
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import { msalInstance } from "vue-msal-browser";
import * as XLSX from "xlsx";

export default {
  props: ["id"],
  components: { TextBox, ClickableTextBox, Loading, ImageHover, Tabs, Tab },
  data: () => ({
    customer_info: null,
    customer_sales_history: null,
    sales_history_total: null,
    open_post_totals: null,
    customer_contact_info: null,
    item_history_total: null,
    item_history: null,
    open_posts: null,
    open_orders: null,
    open_orders_totals: null,
    note_context: null,
    customer_notes: null,
    loading: true,
  }),
  created() {
    this.getData(this.id);
  },
  watch: {
    id(newid) {
      this.getData(newid);
    },
  },
  methods: {
    getData(id) {
      this.loading = true;
      const customer_contact_info = `contact-info/${id}`;
      const customer_openposts = `open-posts/${id}`;
      const customer_openorders = `open-orders/${id}`;
      const customer_item_history = `item-history/${id}`;
      const customer_get_notes = `customer-notes/${id}`;
      request(`customer/${id}`, "GET").then(
        ({ customer_info, customer_sales_history, sales_history_total }) => {
          this.customer_info = customer_info[0];
          this.customer_sales_history = customer_sales_history;
          this.sales_history_total = sales_history_total;
        }
      );

      request(customer_contact_info, "GET").then(({ contact_info }) => {
        this.customer_contact_info = contact_info;
      });

      request(customer_openposts, "GET").then(({ open_posts }) => {
        const { posts, total_price } = open_posts;
        this.open_posts = posts;
        this.open_post_totals = total_price;
      });

      request(customer_openorders, "GET").then(({ open_orders, open_orders_totals }) => {
        this.open_orders = open_orders;
        this.open_orders_totals = open_orders_totals
      });

      request(customer_item_history, "GET").then(({ item_history }) => {
        const { items, item_history_total } = item_history;
        this.item_history = items;
        this.item_history_total = item_history_total;
      });

      request(customer_get_notes, "GET").then(({ customer_notes }) => {
        this.customer_notes = customer_notes;
        this.loading = false;
      });
    },

    postNote() {
      const data = {
        debiteur: this.id,
        gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
        datum: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        aantekeningen: this.note_context,
      };

      request("post-customer-note", "POST", data).then((values) => {
        let toastProps = {};
        if (hasIndex(values.message, "toegevoegd")) {
          toastProps = {
            message: values.message,
            type: "success",
          };
          this.$refs["note_modal"].hide();
          this.customer_notes.unshift({
            Aantekeningen: this.note_context,
            Datum: moment().format("YYYY-MM-DD"),
            Gebruiker: msalInstance.getAllAccounts()[0].idTokenClaims.name,
            Debiteur: this.id,
          });
          this.note_context = "";
        } else {
          toastProps = {
            message: values.error,
            type: "danger",
          };
          this.$refs["note_modal"].hide();
        }

        this.$store.dispatch("addNotification", toastProps);
      });
    },

    createExcel() {
      let open_posts_array = [];
      this.open_posts.forEach((i) => {
        open_posts_array.push({
          "Doc type": i.type,
          "Doc nummer": i.factuur,
          "Factuurdatum": i.datum,
          "Afleverdatum": i.afleverdatum,
          "Totaalbedrag": Number(i.bedrag.replace(/,/g, '')),
          "Openstaand": Number(i.openstaand.replace(/,/g, '')),
          "Omschrijving": i.omschrijving,
          "Bankomschrijving A": i.bankomschrijvingA,
          "Bankomschrijving B": i.bankomschrijvingB,
          "Valuta": i.valuta,
          "Valuta bedrag": Number(i.valutabedrag.replace(/,/g, '')),
          "Valuta open": Number(i.openvaluta.replace(/,/g, '')),

        });
      });

      var telWS = XLSX.utils.json_to_sheet(open_posts_array);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `open_posten_${this.id}_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `open_posten_${this.id}_${this.moment().format("DD-MM-YYYY")}.xlsx`
      );
    },

  },
};
</script>
